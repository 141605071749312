<template>
  <div class="webmap">
    <div class="wijkmap map" ref="mapcontainer">
      <l-map ref="map" :zoom="zoom" :minZoom="mapConfig.minZoom" :maxZoom="mapConfig.maxZoom" :bounds="bounds" :options="{
        zoomSnap: 0.1,
        zoomDelta: 0.1,
        zoomControl: mapConfig.zoomControl,
        attributionControl: mapConfig.attributionControl,
        gestureHandling: false,
        scrollWheelZoom: false
      }" @leaflet:load="initMap" :crs="mapConfig.RD">
        <l-control-attribution position="bottomright" :prefix="`&copy; <a href='https://pythonunited.com'>Python United</a>`
          " />
      </l-map>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import L from 'leaflet';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import 'leaflet/dist/leaflet.css';
import 'proj4';
import 'proj4leaflet';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Utils } from '../../ts/utils';

import { LControl, LControlAttribution, LMap } from 'vue2-leaflet';

import mapSettings from '../constants/mapSettings';

export default {
  name: 'WijkenKaart',
  props: {
    compareWijken: {
      type: Boolean,
      required: false,
    },
    showCultivated: {
      type: Boolean,
      required: false,
      default: false,
    },
    showNeighbourhoods: {
      type: Boolean,
      required: false,
      default: true,
    },
    showBackgroundLayers: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  components: {
    LMap,
    LControl,
    LControlAttribution,
  },

  data() {
    return {
      zoom: mapSettings.zoom,
      bounds: new L.LatLngBounds(mapSettings.bounds),
      mapInitialized: false,
      stadsdelen: [],
      wijken: [],
      indicator: Utils.Instance.rootIndicator,
      indicatorObj: null,
      wijkMeetwaarden: [],
    };
  },

  mounted() {
    if (!this.compareWijken) {
      Utils.instance.getStadsdelen().then(data => {
        this.stadsdelen = data;
      });
    } else {
      this.kompasLegend = Utils.getKompasLegend();
      Utils.Instance.getWijkenMeetwaarden(this.selectedVariant).then(data => {
        this.wijkMeetwaarden = data;
        this.updateWijkenColor();
      });
      this.loadSelectedIndicator();
    }
    Utils.instance.getWijken().then(data => {
      this.wijken = data;
    });
    window.addEventListener('resize', this.handleWindowResize);
  },

  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleWindowResize);
  },

  methods: {
    ...mapMutations(['setSelectedWijk', 'setSelectedStadsdeel']),
    ...mapActions(['selectWijkAndStadsdeel']),

    loadSelectedIndicator() {
      Utils.Instance.getIndicator(this.selectedIndicatorId).then(data => {

        this.indicatorObj = data;

        if (this.showCultivated) {
          this.updateWijkenColor(this.wijkBebouwingJson);
        }

        if (this.showNeighbourhoods) {
          this.updateWijkenColor(this.wijkGrensJson, true);
        }
      });
    },

    updateWijkenColor(gJson, transparent) {

      let geoJson = gJson ? gJson : this.wijkGrensGeoJson;

      if (geoJson && this.compareWijken) {
        geoJson.eachLayer(layer => {
          const wijkId = parseInt(layer.feature.properties.CODE);
          const colorClass = this.getScoreLevel(wijkId);
          let removeClass = null;
          layer._path.classList.forEach(item => {
            if (item.indexOf('color-') != -1) {
              removeClass = item;
            }
          });
          layer._path.classList.remove(removeClass);
          if (colorClass) {
            layer._path.classList.add(colorClass);
          }
          layer._path.classList.add('compare-wijk');
          if (transparent) {
            layer._path.classList.add('transparent');
          }
        });
      }
    },

    getScoreLevel(wijkId) {
      if (this.wijkMeetwaarden && this.wijkMeetwaarden[wijkId] && this.selectedIndicatorId && this.indicatorObj) {
        let score = this.wijkMeetwaarden[wijkId][this.selectedIndicatorId];
        if (score) {
          if (score[0] === 0 && score[1] === 0) {
            score = -1;
          } else {
            score = score[0];
          }
        }
        let level = '';
        let legend = this.kompasLegend.stoplicht;
        let prefix = '';

        if (this.indicatorObj.kleurenschema.toLowerCase().indexOf('blauw') >= 0) {
          legend = this.kompasLegend.blauw;
          prefix += 'profile-';
        }

        for (let record of legend) {
          if (score >= record.min && score <= record.max) {
            level = record.level;
            break;
          }
        }

        // undefined scores
        if (score === -1) {
          level = 'none';
        }

        return `color-${prefix}${level}`;
      }
      return;
    },

    handleWindowResize(event) {
      // we need to listen to resize since zoom on map changes too

      // simple debounce pattern..
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (!this.compareWijken) {
          this.zoomToStadsdeel();
        } else {
          this.$refs.map.mapObject.fitBounds(this.wijkGrensGeoJson.getBounds());
        }
      }, 100);
    },

    updateSelectedStadsdeelClass() {
      if (this.stadsdeelGeoJson) {
        this.stadsdeelGeoJson.eachLayer(layer => {
          const isSelected =
            !this.selectedStadsdeel ||
            parseInt(layer.feature.properties.CODE) ===
            this.selectedStadsdeel.id;
          if (isSelected) {
            layer._path.classList.remove('hidden');
          } else {
            layer._path.classList.add('hidden');
          }
        });
      }
    },

    updateSelectedWijkClass() {
      if (this.wijkGrensGeoJson) {
        this.wijkGrensGeoJson.eachLayer(layer => {
          const isSelected =
            this.selectedWijk &&
            parseInt(layer.feature.properties.CODE) === this.selectedWijk.id;

          const isHidden =
            !this.compareWijken &&
            (!this.selectedStadsdeel ||
              parseInt(layer.feature.properties.StadsdeelCode) !==
              this.selectedStadsdeel.id);

          if (isSelected) {
            layer._path.classList.add('selected');
          } else {
            layer._path.classList.remove('selected');
          }

          if (isHidden) {
            layer._path.classList.add('hidden');
          } else {
            layer._path.classList.remove('hidden');
          }
        });
      }
    },

    initMap() {
      if (this.showBackgroundLayers) {
        this.loadBackgroundLayers();
      }

      this.loadWijkenLayers();

      if (!this.compareWijken) {
        this.loadStadsdelenLayer();
      }

      this.$refs.map.mapObject.on('overlayadd', () => {
        // always place the wijken layer on top
        if (this.wijkGrensGeoJson) {
          this.wijkGrensGeoJson.bringToFront();
        }
      });
    },

    zoomToStadsdeel() {
      if (this.stadsdeelGeoJson) {
        if (this.selectedStadsdeel) {
          this.stadsdeelGeoJson.eachLayer(layer => {
            if (
              parseInt(layer.feature.properties.CODE) ===
              this.selectedStadsdeel.id
            ) {
              // Zoom to that layer.
              this.$refs.map.mapObject.fitBounds(
                layer.getBounds(),
                mapSettings.fitBoundsOptions
              );
            }
          });
        } else {
          // geen stadsdeel meer geselecteerd. Zoom uit
          //this.$refs.map.mapObject.fitBounds(mapSettings.bounds, mapSettings.fitBoundsOptions);
          this.$refs.map.mapObject.fitBounds(mapSettings.bounds);
        }
      }
      if (this.wijkGrensGeoJson) {
        // fix glitch where this layer is not on top anymore
        this.wijkGrensGeoJson.bringToFront();
      }
    },

    getStadsdeel(stadsdeelId) {
      for (const stadsdeel of this.stadsdelen) {
        if (stadsdeelId === stadsdeel.id) {
          return stadsdeel;
        }
      }
    },
    getWijk(wijkId) {
      for (const wijk of this.wijken) {
        if (wijkId === wijk.id) {
          return wijk;
        }
      }
    },
    loadStadsdelenLayer() {
      let stadsdelenURL = document.body.getAttribute('data-stadsdelen-geojson');
      let stadsdelenPromise = Utils.Instance.getJsonPromise(stadsdelenURL);

      // we need the stadsdelen so we can get the slug for the classnames
      Utils.instance.getStadsdelen().then(data => {
        this.stadsdelen = data;

        stadsdelenPromise.then(stadsdeelJson => {
          const onEachStadsdeelFeature = (feature, layer) => {
            layer.on({
              click: e => {
                const stadsdeel = this.getStadsdeel(
                  parseInt(feature.properties.CODE)
                );
                this.setSelectedStadsdeel(stadsdeel);
              },
            });
          };

          const stadsdelenStyle = feature => {
            const stadsdeel = this.getStadsdeel(
              parseInt(feature.properties.CODE)
            );
            const slug = stadsdeel.slug.replace('-', '');
            return {
              className: `stadsdeelFeature ${slug}`,
            };
          };

          this.stadsdeelGeoJson = L.Proj.geoJson(stadsdeelJson, {
            style: stadsdelenStyle,
            onEachFeature: onEachStadsdeelFeature,
          }).addTo(this.$refs.map.mapObject);
        });
      });
    },

    loadWijkenLayers() {
      let wijkenURL = document.body.getAttribute('data-wijken-geojson');
      let wijkenBebouwdURL = document.body.getAttribute(
        'data-wijken-bebouwd-geojson'
      );
      let wijkGrensPromise = Utils.Instance.getJsonPromise(wijkenURL);
      let wijkenBebouwdPromise = Utils.Instance.getJsonPromise(
        wijkenBebouwdURL
      );

      Promise.all([wijkenBebouwdPromise, wijkGrensPromise]).then(allData => {
        const wijkBebouwingJson = allData[0];
        const wijkGrensJson = allData[1];

        const wijkenStyle = feature => {
          return {
            // fillColor: '#fff', // this.getFeatureColor(feature),
            className: 'wijkFeature',
          };
        };

        const onEachWijkFeature = (feature, layer) => {
          layer.on({
            click: e => {
              const wijk = this.getWijk(parseInt(feature.properties.CODE));
              this.selectWijkAndStadsdeel(wijk.id);
            },
          });
        };

        if (this.showCultivated) {
          this.wijkBebouwingJson = L.Proj.geoJson(wijkBebouwingJson, {
            style: wijkenStyle,
            onEachFeature: onEachWijkFeature,
          }).addTo(this.$refs.map.mapObject);

          this.updateWijkenColor(this.wijkBebouwingJson);
        }

        if (this.showNeighbourhoods) {
          this.wijkGrensGeoJson = L.Proj.geoJson(wijkGrensJson, {
            style: wijkenStyle,
            onEachFeature: onEachWijkFeature,
          }).addTo(this.$refs.map.mapObject);

          this.updateWijkenColor(this.wijkGrensGeoJson, true);

          // fix zoom to bounds
          this.$refs.map.mapObject.fitBounds(this.wijkGrensGeoJson.getBounds());

          this.wijkGrensGeoJson.bindTooltip(layer => {
            return `${layer.feature.properties.NAAM}`;
          });
        }

        this.updateSelectedWijkClass();
      });
    },

    loadBackgroundLayers() {
      // de combinatie met een TMS en een CRS werkt niet lekker met normale Vue2Leaflet l-tile-layer
      // ik weet niet waarom, maar dit werkt ook: gewoon via olderwetse javascript de boel
      // fixen
      const streets = new L.TileLayer(
        'https://www.openbasiskaart.nl/mapcache/tms/1.0.0/osm@rd/{z}/{x}/{y}.png',
        {
          tms: true,
          minZoom: 0,
          maxZoom: 15,
          // maxNativeZoom: 14,
          detectRetina: false,
          continuousWorld: true,
          opacity: 0.4,
        }
      );

      streets.addTo(this.$refs.map.mapObject); // default geselecteerde baselayer
    },
    resetData() {
      Utils.Instance.getWijkenMeetwaarden(this.selectedVariant).then(data => {
        this.wijkMeetwaarden = data;
        this.updateWijkenColor();
      });
    }
  },

  computed: {
    ...mapGetters(['selectedWijk', 'selectedStadsdeel', 'selectedIndicatorId', 'selectedVariant']),
    mapConfig() {
      return {
        ...mapSettings,
      };
    },
  },

  watch: {
    selectedStadsdeel() {
      this.updateSelectedWijkClass();
      if (!this.compareWijken) {
        this.updateSelectedStadsdeelClass();
        this.zoomToStadsdeel();
      }
    },
    selectedWijk() {
      this.updateSelectedWijkClass();
    },
    selectedIndicatorId() {
      this.loadSelectedIndicator();
    },
    selectedVariant() {
      this.resetData();
    }
  },
};
</script>

<style lang="scss">
@import '../../wildsea/Styles/variables';

.wijkmap {
  height: 860px;
}

@media (max-width: 1280px) {
  .wijkmap {
    height: 640px;
  }
}

@media (max-width: 1024px) {
  .wijkmap {
    height: 612px;
  }
}

@media (max-width: 800px) {
  .wijkmap {
    height: 400;
  }
}

.wijkFeature {
  fill: #000;
  fill-opacity: 0;
  stroke-width: 1;
  stroke: #000;

  &:hover {
    fill-opacity: 0.1;
  }

  &.selected {
    // stroke-width: 3;
    fill-opacity: 0.3;
  }

  &.hidden {
    display: none;
  }

  &.compare-wijk {
    fill-opacity: 1;

    &.transparent {
      fill-opacity: 0.5;
    }

    &.selected {
      stroke-width: 3;
      stroke: #9441a5;
    }
  }

  $metric-level-list: $metric-level-1 $metric-level-2 $metric-level-3 $metric-level-4 $metric-level-5 $metric-level-6 $metric-level-7;

  @mixin get-fill-from-metric-level-list($index) {
    fill: nth($metric-level-list, $index);
  }

  @for $i from 1 through 7 {
    &.color-#{$i} {
      @include get-fill-from-metric-level-list($i);
    }
  }

  $metric-level-profile-list: $metric-level-profile-1 $metric-level-profile-2 $metric-level-profile-3 $metric-level-profile-4 $metric-level-profile-5 $metric-level-profile-6 $metric-level-profile-7;

  @mixin get-fill-from-metric-level-profile-list($index) {
    fill: nth($metric-level-profile-list, $index);
  }

  @for $i from 1 through 7 {
    &.color-profile-#{$i} {
      @include get-fill-from-metric-level-profile-list($i);
    }
  }

  &.color-profile-none,
  &.color-none {
    fill: $metric-level-0;
  }
}

.stadsdeelFeature {
  fill: $kompas-map-theme-color-default;
  fill-opacity: 0.3;
  stroke-width: 1;
  stroke: #000;

  &.hidden {
    display: none;
  }

  &.centrum {
    fill: $kompas-map-theme-color-centrum;
  }

  &.west {
    fill: $kompas-map-theme-color-west;
  }

  &.oost {
    fill: $kompas-map-theme-color-oost;
  }

  &.zuid {
    fill: $kompas-map-theme-color-zuid;
  }

  &.oudewijken {
    fill: $kompas-map-theme-color-oudewijken;
  }

  &.haren {
    fill: $kompas-map-theme-color-haren;
  }

  &.tenboer {
    fill: $kompas-map-theme-color-tenboer;
  }
}

.leaflet-container {
  background: #fff;
}
</style>
