<template>
  <div :class="`block block-comparison block-comparison-${widget}column`">
    <div id="block-comparison-details" class="block-comparison-details">

      <!-- KOMPAS SIDEBAR -->
      <div id="kompas-foldout-wrapper" class="kompas-foldout-wrapper">

        <!-- Kompas Sidebar Buttons -->
        <ul id="kompas-sidebar-buttons" class="kompas-sidebar-buttons">
          <!-- 'Kompas Viewmode'-buttons -->
          <li>
            <button type="button" class="button icon-only transparent block-kompas-viewmode-trigger"
              title="Toon Wijkkaart" :class="showVisual === 'wijkkaart' ? 'active' : ''"
              v-on:click="showVisual = 'wijkkaart'">
              <i class="fa fa-map-marked-alt"></i>
            </button>
          </li>
          <li>
            <button type="button" class="button icon-only transparent block-kompas-viewmode-trigger"
              title="Toon Kompasvergelijker" :class="showVisual === '' ? 'active' : ''" v-on:click="showVisual = ''">
              <i class="fa fa-table"></i>
            </button>
          </li>
          <li>
            <button type="button" class="button icon-only transparent block-kompas-viewmode-trigger"
              title="Toon Staafdiagram" :class="showVisual === 'barchart' ? 'active' : ''"
              v-on:click="showVisual = 'barchart'">
              <i class="fa fa-chart-bar"></i>
            </button>
          </li>
        </ul>
      </div>

      <!-- INDICATOR BARCHART -->
      <indicator-barchart v-if="showVisual == 'barchart'"></indicator-barchart>

      <!-- KOMPAS CHART -->
      <wijken-kaart v-if="showVisual == 'wijkkaart'" :compare-wijken="true" :show-neighbourhoods="true"
        :show-cultivated="true" :show-background-layers="false"></wijken-kaart>

      <template v-if="widget === 'single' && !showVisual">
        <div id="block-comparison-details-scroller" class="scroller">
          <ul class="neighborhoods">
            <li v-for="item in getWijkmeetwaarden()" :key="item[0]"
              :class="`level-${getScoreLevel(item[0])} ${selfClass(item[0])} ${noArrowClass(item[1])}`">
              <span v-on:click="selectWijkAndStadsdeel(getWijk(item).id)">
                {{ getWijk(item).naam }}
              </span>
            </li>
          </ul>
        </div>
      </template>

      <template v-if="widget === 'multi' && !showVisual">
        <ul class="neighborhoods">
          <li v-for="stadsdeel in stadsdelen" :key="stadsdeel.id">
            <h3>{{ stadsdeel.naam }}</h3>
            <div v-for="wijk in stadsdeel.wijk_set" :key="wijk.id"
              :class="`neighborhood level-${getScoreLevel(wijk.id)} ${selfClass(wijk.id)}`"
              v-on:click="selectWijkAndStadsdeel(wijk.id)">
              <span>{{ wijk.naam }}</span>
            </div>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Utils } from '../../ts/utils';
import IndicatorBarchart from './IndicatorBarchart.vue';
import WijkenKaart from './WijkenKaart.vue';

export default {
  name: 'KompasVergelijker',

  components: {
    IndicatorBarchart,
    WijkenKaart,
  },

  props: {
    // either 'single' or 'multi'
    widget: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      label: '',
      kompasLegend: [],
      wijken: [],
      stadsdelen: [],
      wijkMeetwaarden: {},
      indicator: Utils.Instance.rootIndicator,
      showVisual: 'wijkkaart',
    };
  },

  mounted() {
    this.kompasLegend = Utils.getKompasLegend();
    Utils.Instance.getWijkenMeetwaarden(this.selectedVariant).then(data => {
      this.wijkMeetwaarden = data;
    });
    Utils.instance.getWijken().then(data => {
      this.wijken = data;
    });
    Utils.instance.getStadsdelen().then(data => {
      this.stadsdelen = data;
    });
  },

  methods: {
    ...mapMutations(['setSelectedWijk']),
    ...mapActions(['selectWijkAndStadsdeel']),
    enabled() {
      return this.indicatorStore && this.indicatorStore.indicator;
    },
    selfClass(wijkId) {
      let clazz = '';
      if (this.selectedWijk) {
        clazz = parseInt(wijkId) === this.selectedWijk.id ? 'self' : '';
      }
      return clazz;
    },
    noArrowClass(score) {
      let clazz = '';
      if (this.selectedWijk) {
        clazz = score < this.wijkMeetwaarden[this.selectedWijk.id][this.selectedIndicatorId][0] ? 'no-arrow' : '';
      }
      return clazz;
    },
    resetData() {
      Utils.Instance.getWijkenMeetwaarden(this.selectedVariant).then(data => {
        this.wijkMeetwaarden = data;
      });
    },
    getWijk(wijkId) {
      wijkId = parseInt(wijkId);

      for (const wijk of this.wijken) {
        if (wijkId === wijk.id) {
          return wijk;
        }
      }
    },
    getScoreLevel(wijkId) {
      if (this.wijkMeetwaarden && this.wijkMeetwaarden[wijkId]) {
        let score = this.wijkMeetwaarden[wijkId][this.selectedIndicatorId];

        if (score) {
          if (score[0] === 0 && score[1] === 0) {
            score = -1;
          } else {
            score = score[0];
          }
        }
        let level = '';
        let legend = this.kompasLegend.stoplicht;
        let prefix = '';

        let x = this.indicatorId
        if (
          this.indicatorStore &&
          this.indicatorStore.kleurenschema.toLowerCase().indexOf('blauw') >= 0
        ) {
          legend = this.kompasLegend.blauw;
          prefix += 'profile-';
        }

        for (let record of legend) {
          if (score >= record.min && score <= record.max) {
            level = record.level;
            break;
          }
        }

        // undefined scores
        if (score === -1) {
          level = 'none';
        }

        return `${prefix}${level}`;
      }
    },
    getWijkmeetwaarden() {
      if (this.wijkMeetwaarden && this.wijkMeetwaarden.length) {
        let mwpi = {};
        for (let key in this.wijkMeetwaarden) {
          mwpi[key] = this.wijkMeetwaarden[key][this.selectedIndicatorId][0];
        }

        // Create items array
        const items = Object.keys(mwpi).map(function (key) {
          return [key, mwpi[key]];
        });

        // Sort the array based on the second element
        items.sort(function (first, second) {
          return second[1] - first[1];
        });

        return items;
      }
    },
  },

  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(['selectedWijk', 'indicatorStore', 'selectedIndicatorId', 'selectedVariant']),
    indicatorId() {
      if (this.indicatorStore.indicator) {
        return this.indicatorStore.indicator.id;
      } else {
        return this.indicator;
      }
    },
  },

  watch: {
    selectedVariant() {
      this.resetData();
    }
  },
};
</script>

<style lang="scss">
.scroller {
  overflow: scroll !important;
}

.level-none {
  background-color: #e9ecef;
}

@media (max-width: 640px) {
  body.kompas {
    .block.block-filters ul li {
      display: contents;
    }
  }
}
</style>
